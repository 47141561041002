import store from "@/store";
import axios from "axios";
var state = store.state;
export default {
  install(Vue) {
    var self = Vue.config.globalProperties;
    Vue.config.globalProperties.http = {
      post(url, data, options) {
        return new Promise((resolve) => {
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (state.token) {
            options.headers["x-auth"] = state.token;

            data.user_id = state.currentUser.id;
          }
          console.log(options);
          if (localStorage.currentUser) {
            let current_user = JSON.parse(localStorage.currentUser);
            if (!data.user_id) {
              data.user_id = current_user?.id;
            }
          }
          data.lang = Vue.config.globalProperties.$i18n.locale;
          axios.post(state.baseUrl + url, data, options).then((resp) => {
            console.log(resp.headers["x-auth"]);
            if (resp.headers["x-auth"]) {
              state.token = resp.headers["x-auth"];
              console.log(data.pass);
              resp.data.data.pass = self.$e.encrypt(data.pass);
              state.currentUser = resp.data.data;
              localStorage.currentUser = JSON.stringify(resp.data.data);
              console.log(state.token);
            }
            if (resp.data.status) {
              resolve(resp.data);
            } else {
              resolve(resp.data);

            }
          });
        });
      },
      do(url, data, options) {
        return new Promise((resolve) => {
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (state.token) {
            options.headers["x-auth"] = state.token;
            data.user_id = state.currentUser.id;
          }
          console.log(options);
          if (localStorage.currentUser) {
            let current_user = JSON.parse(localStorage.currentUser);
            if (!data.user_id) {
              data.user_id = current_user?.id;
            }
          }
          data.lang = Vue.config.globalProperties.$i18n.locale;
          var formData = new FormData();
          for (var key in data) {
            formData.append(key, data[key]);
          }
          axios.post(state.baseUrl + url, formData, options).then((resp) => {
            console.log(resp.headers["x-auth"]);
            if (resp.headers["x-auth"]) {
              state.token = resp.headers["x-auth"];
              console.log(data.pass);
              resp.data.data.pass = self.$e.encrypt(data.pass);
              state.currentUser = resp.data.data;
              localStorage.currentUser = JSON.stringify(resp.data.data);
              console.log(state.token);
            }
            if (resp.data.status) {
              resolve(resp.data);
            } else {
              self.popup.alert({
                title: "popups.error",
                msg: resp.data.msg
              });
            }
          });
        });
      },
      put(url, id, data, options) {
        return new Promise((resolve) => {
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (state.token) {
            options.headers["x-auth"] = state.token;
            data.user_id = state.currentUser.id;
          }
          const currentUser = JSON.parse(localStorage.currentUser);
          data.user_id = currentUser.id;
          console.log(options);
          // data.lang = Vue.config.globalProperties.$i18n.locale
          axios
            .put(state.baseUrl + url + "/" + id, data, options)
            .then((resp) => {
              console.log(resp.headers["x-auth"]);
              if (resp.headers["x-auth"]) {
                state.token = resp.headers["x-auth"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(state.token);
              }
              if (resp.data.status) {
                resolve(resp.data);
              } else {
                self.popup.alert({
                  title: "popups.error",
                  msg: resp.data.msg
                });
              }
            });
        });
      },
      get(url, options) {
        return new Promise((resolve) => {
          var data = {};
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (state.token) {
            options.headers["x-auth"] = state.token;
            data.user_id = state.currentUser.id;
          }
          if (localStorage.currentUser) {
            let current_user = JSON.parse(localStorage.currentUser);
            data.user_id = current_user.id;
          }
          console.log(options);
          data.lang = Vue.config.globalProperties.$i18n.locale;
          axios
            .get(
              state.baseUrl + url + "?" + new URLSearchParams(data).toString(),
              data,
              options
            )
            .then((resp) => {
              // console.log("the status is",resp.data.status)
              console.log(resp.headers["x-auth"]);
              if (resp.headers["x-auth"]) {
                state.token = resp.headers["x-auth"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(state.token);
              }
              if (resp.data.status) {
                resolve(resp.data);
              } else {
                self.popup.alert({
                  title: "popups.error",
                  msg: resp.data.msg
                });
              }
            });
        });
      },
      delete(url, id, options) {
        return new Promise((resolve) => {
          var data = {};
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (state.token) {
            options.headers["x-auth"] = state.token;
            data.user_id = state.currentUser.id;
          }
          console.log(options);
          data.lang = Vue.config.globalProperties.$i18n.locale;
          axios
            .delete(
              state.baseUrl +
                url +
                "/" +
                id +
                "?" +
                new URLSearchParams(data).toString(),
              options
            )
            .then((resp) => {
              console.log(resp.headers["x-auth"]);
              if (resp.headers["x-auth"]) {
                state.token = resp.headers["x-auth"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(state.token);
              }
              if (resp.data.status) {
                resolve(resp.data);
              } else {
                self.popup.alert({
                  title: "popups.error",
                  msg: resp.data.msg
                });
              }
            });
        });
      },
    };
  },
};
