import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";

/* import HomePage from '../views/HomePage.vue'
 */
const routes = [


{
  path:'/',
  name:'home',
  component: () => import('../views/homePage.vue')
},

  
{

  path:'/set-password',
  name:'setPassword',
  component: () => import('../views/setPassword.vue')
},
{
  path:'/sorry',
  name:'sorry',
  component: () => import('../views/sorryPage.vue')

},
{
  path:'/success',
  name:'success',
  component: () => import('../views/successPage.vue')

}
  

  

]

const router = createRouter({
  scrollBehavior() {

    return { top: 0 }

  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((routeTo, routeFrom, next) => {

  if (routeTo.name =='setPassword'&& !store.state.id ) {
    
    router.push('/')
  }
  
  
  console.log('from :', routeFrom);
  console.log('to :', routeTo);
  console.log('next :', next());

  





})

export default router
