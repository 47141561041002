<template>
  
  <router-view  />
</template>
<script>


export default {
  methods: {
    
    
    
  },
  mounted() {
     // eslint-disable-next-line 
     
     console.log(' app path  ',this.$route.fullPath)
     
   
     
    },
    created() {
     
    
  },
}
</script>
<style lang="scss">


nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #d6c678;
    }
  }
}
</style>
