import $ from "jquery";
window.$ = $
import { Modal } from "bootstrap";
export default {
  install(Vue) {
    Vue.config.globalProperties.popup = {
      alert(options) {
        console.log(options);
        return new Promise((resolve) => {
          var self = Vue.config.globalProperties
          var x = $(`<div class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog   "style='top: 35%;'>
                  <div class="modal-content" style="text-align: center;">
                    <div class="modal-header">
                      <h5 class="modal-title ${self.$i18n.locale == 'ar' ? 'text-end' : ''}" style="width:100%">${self.$t(options.title)}</h5>
                    </div>
                    <div class="modal-body ${self.$i18n.locale == 'ar' ? 'text-end' : ''}">
                     <h5>${!options.msg ? "" : options.msg.includes('.') && !options.msg.includes(' ') ? self.$t(options.msg) : options.msg}</h5>
                    </div>
                    <div class="modal-footer ${self.$i18n.locale == 'ar' ? 'justify-content-start' : ''}">
                     <button type="button" class="btn btn-info mx-auto" data-bs-dismiss="modal">${self.$t('popups.ok')}</button>
                    </div>
                  </div>
                </div>
              </div>`)
          $("body").append(x)

          var modal = new Modal(x)
          modal.show()

          $(x).find("button").one("click", function () {
            setTimeout(() => {
              $(x).remove()
              resolve()
            }, 300)
          })
        })
      },
      confirm(options) {
        return new Promise((resolve) => {
          var self = Vue.config.globalProperties
          var x = $(`<div class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" style='top: 35%;' >
                  <div class="modal-content" style="text-align: center;">
                    <div class="modal-header">
                      <h5 class="modal-title ${self.$i18n.locale == 'ar' ? 'text-end' : ''}" style="width:100%">${self.$t(options.title)}</h5>
                    </div>
                    <div class="modal-body ${self.$i18n.locale == 'ar' ? 'text-end' : ''}">
                     <h5>${self.$t(options.msg)}</h5>
                    </div>
                    <div class="modal-footer justify-content-start ${self.$i18n.locale == 'ar' ? 'justify-content-start' : ''}">
                     <button type="button" class="btn btn-success " data-bs-dismiss="modal">${self.$t('popups.ok')}</button>
                     <button type="button" class="btn btn-light " data-bs-dismiss="modal">${self.$t('popups.cancel')}</button>
                    </div>
                  </div>
                </div>
              </div>`)
          $("body").append(x)

          var modal = new Modal(x)
          modal.show()

          $(x).find("button:first").one("click", function () {
            setTimeout(() => {
              $(x).remove()
              resolve(true)
            }, 300)
          })
          $(x).find("button:last").one("click", function () {
            setTimeout(() => {
              $(x).remove()
              resolve(false)
            }, 300)
          })

        })
      },
      prompt(options) {
        return new Promise((resolve) => {
          var self = Vue.config.globalProperties
          var x = $(`<div class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog ">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title ${self.$i18n.locale == 'ar' ? 'text-end' : ''}" style="width:100%">${self.$t(options.title)}</h5>
                    </div>
                    <div class="modal-body ${self.$i18n.locale == 'ar' ? 'text-end' : ''}">
                     <h5>
                     <input type="${options.type ? options.type : 'text'}" class="form-control" placeholder="${options.placeholder ? options.placeholder : ''}">
                     </h5>
                    </div>
                    <div class="modal-footer ${self.$i18n.locale == 'ar' ? 'justify-content-start' : ''}">
                     <button type="button" class="btn btn-success " data-bs-dismiss="modal">${self.$t('popups.ok')}</button>
                     <button type="button" class="btn btn-light " data-bs-dismiss="modal">${self.$t('popups.cancel')}</button>
                    </div>
                  </div>
                </div>
              </div>`)
          $("body").append(x)

          var modal = new Modal(x)
          modal.show()

          $(x).find("button:first").one("click", function () {
            setTimeout(() => {
              $(x).remove()
              var value = $(x).find("input").val()
              resolve(value)
            }, 300)
          })
          $(x).find("button:last").one("click", function () {
            setTimeout(() => {
              $(x).remove()
              resolve(false)
            }, 300)
          })

        })
      },
      modal(options){
        return new Promise((resolve)=>{
          var inputs = []
          var self = Vue.config.globalProperties
          var x = $(`<div class="modal  fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content text-right " >
              <div class="modal-header bg-theme">
                <h5 class="modal-title ${self.$i18n.locale=='ar'?'text-end':''}" style="width:100%">${self.$t(options.title)}</h5>
              </div>
              <div class="modal-body ${self.$i18n.locale=='ar'?'text-end':''}">
               <h5>
               ${options.template?options.template:''}
               </h5>
               <h5 class='inputs'></h5>
              </div>
              <div class="buttons modal-footer d-flex ${self.$i18n.locale=='ar'?'justify-content-start':'justify-content-end'}">

              </div>
            </div>
          </div>
        </div>`)
          if(options.inputs){

            options.inputs.forEach(function(input){
              var inputOptions
              var inputTemplate = $(`
              <div class="mb-3">
                <label for="message-text" class="col-form-label">${input.label?input.label:''}</label>
                </div>
              `)
              if(input.type=='select'){
                inputOptions = input.options.map(option=>`
                <option value=${option.value}  ${option.selected ?'selected':''}> ${option.label}
                </option>`).join("\n")
                $(inputTemplate).append(`<select  class="form-select">${inputOptions}</select>`)
                inputs.push({type:input.type,model:input.model,template:inputTemplate})
              }
              else if(input.type=='radio'){
                inputOptions = input.options.map(option=>`
                <div class="form-check">
                <input class="form-check-input" id="${input.label}-${option.label}" type='radio' name='${input.label}' ${option.checked?'checked':''} value='${option.value}'>
                 <label class="form-check-label" for="${input.label}-${option.label}">${option.label}</label>
                 </div>
                `)
                $(inputTemplate).append(inputOptions) 
                inputs.push({type:input.type,model:input.model,template:inputTemplate})
              }

              //just added textarea to popups service Aug 11th 2023

              else if(input.type== "textarea"){
                $(inputTemplate).append(`

                <textarea class='ms-1 form-control' id="${input.model}"  >  ${input.value ? input.value:'' } </textarea>

                `)

                inputs.push({type:input.type,model:input.model,template:inputTemplate})
              }

              else if(input.type== "files"){
                $(inputTemplate).append(`
                <input type='file' class='ms-1' form-control' multiple='multiple' id='${input.model}' value="${input.value?input.value:''}"> `)

                inputs.push({type:input.type,model:input.model,template:inputTemplate})


              }
              else if(input.type=='checkbox'){
                inputOptions = input.options.map(option=>$(`
                <div class="form-check">
                <input class="form-check-input" id="${input.label}-${option.label}" type='checkbox' ${option.checked?'checked':''} name='${option.label}'>
                 <label class="form-check-label" for="${input.label}-${option.label}">${option.label}</label>
                 </div>
                `))
                $(inputTemplate).append(inputOptions) 
                inputs.push({type:input.type,model:input.model,templates:inputOptions})
              }
              else{
                $(inputTemplate).append(`<input class="ms-1 form-control" type="${input.type}" value="${input.value?input.value:''}" >`)
                inputs.push({type:input.type,model:input.model,template:inputTemplate})
              }
              $(x).find(".inputs").append(inputTemplate)
            })
          }
          if(options.buttons){

             options.buttons.forEach(button=>{
                var buttonTemplate = $(`<button class='btn btn-${button.color} mx-1'>${button.text}</button>`)
                $(buttonTemplate).on("click",()=>{
                  if(button.closer){
                    var data = {}
                    inputs.forEach(input=>{
                      if(input.type=='checkbox'){
                        var values = input.templates.map(oneCheckbox=>{
                          var name = $(oneCheckbox).find("input").prop("name")
                          var value = $(oneCheckbox).find("input").is(':checked')
                          console.log($(oneCheckbox).find("input").prop("outerHTML"))
                          return {[name]:value}
                        })
                        data[input.model] = values
                      }
                      else if(input.type=='radio'){
                        var name = $(input.template).find("input").prop("name")
                         data[input.model] = $(input.template).find(`[name=${name}]:checked`).val()
                      }
                      else if(input.type=='file'){
                        data[input.model] = $(input.template).find(`input`)[0].files.length>1 ? $(input.template).find(`input`)[0].files : $(input.template).find(`input`)[0].files[0]
                      }
                      else if(input.type=="files"){
                        data[input.model] = $(input.template).find(`input`)[0].files.length>1 ? $(input.template).find(`input`)[0].files : $(input.template).find(`input`)[0].files[0]
                      }
                      else if(input.type== 'textarea'){
                        data[input.model] = $(input.template).find(`textarea`).val()
                      }
                      else{
                        data[input.model] = $(input.template).find(`input,select`).val()
                      }

                    })
                    modal.hide()
                    setTimeout(()=>{

                      resolve()
                      $(x).remove()
                    },300)
                  }
                  button.handler(data)
                })
                $(x).find(".buttons").append(buttonTemplate)
            })
          }

        $("body").append(x)

        var modal = new Modal(x)
        modal.show()



        })
      },












    }
  }
}