import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './service/http'
import popups from './service/popups'
import { i18n } from "./i18n";


createApp(App).use(store).use(router).use(http).use(popups).use(i18n).mount('#app')
